<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <div
        class="w-100 d-flex justify-content-between"
        style="color: #595959"
        v-if="model.length"
    >
      <div class="px-2 summary-time">
        <strong>{{ $t('system.summary') }}:</strong> {{ value.timesSum }}
      </div>
      <div class="px-2 summary-time">
        <strong>{{ $t('system.summary_with_subissues') }}:</strong> {{ value.timesSumWithChildren }}
      </div>
    </div>
    <time-entry-table
        :activities="dictionaries[field.dictionary]"
        :data="model"
        :params.sync="syncParams"
        :thead="thead"
        :total="total"
        :users="dictionaries.users"
        @refresh="fetchData"
        @removeElement="removeElement"
        @updateElement="updateElement"
        class="w-100"
        idColumn="time_entries.id"
    ></time-entry-table>
  </base-form-item>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import BaseFormItem from '../../UI/FormItem.vue';
import TimeEntryTable from '../../../../Table/TimeEntry.vue';
import abstractForm from '../../mixin/index';
import notification from '../../../../../notification/notify';
import EventBus from '../../../../../eventBus';
import time from '../../../../../mixin/time';

export default {
  name: 'FieldTimeEntryTable_index',
  mixins: [abstractForm, time],
  data() {
    return {
      loading: false,
      model: [],
      total: 0,
      params: {
        column: 'time_entries.spent_on',
        direction: 'desc',
        page: 1,
        per_page: 10,
      },
      thead: [
        {
          label: 'time_entry_spent_on',
          key: 'time_entries.spent_on',
          sort: true,
          type: 'datetimePicker',
        }, {
          label: 'time_entry_users',
          key: 'time_entry_users.name',
          sort: true,
          source: 'users',
          target: 'time_entry_users.id',
          type: 'select',
        }, {
          label: 'time_entry_activity',
          key: 'enumeration_time_entry_activities.name',
          sort: true,
          source: 'activities',
          target: 'enumeration_time_entry_activities.id',
          type: 'select',
        }, {
          label: 'time_entry_hours',
          key: 'time_entries.hours',
          sort: true,
          type: 'hours',
        }, {
          label: 'time_entry_comment',
          key: 'time_entries.comments',
          sort: true,
          type: 'textarea',
        },
      ],
    };
  },
  components: {
    TimeEntryTable,
    BaseFormItem,
  },
  created() {
    EventBus.$on('FETCH_TIME_ENTRIES', () => {
      this.fetchData();
    });
  },
  beforeMount() {
    this.fetchData();
  },
  beforeDestroy() {
    EventBus.$off('FETCH_TIME_ENTRIES');
  },
  computed: {
    // countWorkedHours() {
    //   if (this.model && this.model.length > 0) {
    //     const seconds = this.model.map(item => this.time2sec(item['time_entries.hours']))
    //       .reduce((acc, val) => acc + val);
    //     return this.convertSecondsToHours(seconds);
    //   }
    //   return '0'
    // },
    syncParams: {
      get() {
        return this.params;
      },
      set(newVal, oldVal) {
        this.params = newVal;
        this.fetchData();
      },
    },
  },
  methods: {
    async removeElement(id) {
      axios.delete(`time_entry/${id}`)
          .then((response) => {
            this.fetchData();
            notification.notify(
                this.$t('notify.success'),
                response.data.message,
                'success');
          })
          .catch(function (error) {
            notification.notify(
                this.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    async updateElement(data, resolve, reject) {
      const newData = _.mapKeys(data, (item, key) => {
        return key.replace('.', '-');
      });
      return axios.post(`time_entry/${data['time_entries.id']}`, newData).then((response) => {
        resolve(response);
        this.fetchData();
      }).catch((error) => {
        const newData = _.mapKeys(error.response.data.errors, (item, key) => {
          return key.replace('-', '.');
        });
        reject(newData);
      });
    },
    async fetchData() {
      let columns = _.map(this.thead, 'key');
      columns.push('time_entry_users.id');
      columns.push('enumeration_time_entry_activities.id');
      columns.push('time_entries.settlement_id');
      columns.push('time_entries.id');
      const index = columns.indexOf('actions');
      columns.splice(index, 1);
      return axios.post('time_entries', {
        ...this.params,
        columns: columns,
        params: this.params,
        filters: {
          issuesIds: [this.$route.query.issueId],
        },
      }).then((response) => {
        this.model = response.data.data;
        this.total = response.data.total;
      });
    },
  },
};
</script>

<style scoped>
.summary-time {
  border-radius: 6px;
  background: #e6e6e6;
  line-height: 30px
}
</style>
